import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Virtual Camera with homee-in-homee and Node-RED",
  "path": "/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/",
  "dateChanged": "2020-03-14",
  "author": "Mike Polinowski",
  "excerpt": "Virtual homee-in-homee with Node-RED",
  "social": "/images/Search/P_SearchThumb_homee.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_homee_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "homee"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Virtual Camera with homee-in-homee and Node-RED' dateChanged='2020-03-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Virtual homee-in-homee with Node-RED' image='/images/Search/P_SearchThumb_homee.png' twitter='/images/Search/P_SearchThumb_homee.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Homee_and_Node-RED/' locationFR='/fr/Software/Other_Platforms/Homee_and_Node-RED/' crumbLabel="homee-in-homee" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "virtual-homee-in-homee-with-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#virtual-homee-in-homee-with-node-red",
        "aria-label": "virtual homee in homee with node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual homee-in-homee with Node-RED`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#alarm-areas"
        }}>{`Alarm Areas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#alarm-area-sensitivity"
        }}>{`Alarm Area Sensitivity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pir-sensor"
        }}>{`PIR Sensor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#alarm-email"
        }}>{`Alarm Email`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#link-areas-with-pir-sensor"
        }}>{`Link Areas with PIR Sensor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#push-notifications"
        }}>{`Push Notifications`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#alarmserver"
        }}>{`Alarmserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#alarm-trigger"
        }}>{`Alarm Trigger`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-reboot"
        }}>{`Camera Reboot`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nightvision-mode"
        }}>{`Nightvision Mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nightvision-ir-leds"
        }}>{`Nightvision IR LEDs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preset-positions"
        }}>{`Preset Positions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-recording"
        }}>{`Manual Recording`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pan--tilt"
        }}>{`Pan & Tilt`}</a></li>
    </ul>
    {/* /TOC */}
    <p><strong parentName="p">{`WiP`}</strong></p>
    <h2 {...{
      "id": "alarm-areas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-areas",
        "aria-label": "alarm areas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Areas`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/46534583be4f5071266559f4cff19142/c929c/homee_virtual_INSTAR_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAu0lEQVQI1y3BvQqCQAAAYB+0x+g1amloaYiICqcGqyFIRfoxOdFKzVPv7EzQfjAqvQsNWvo+DmoQai4ywgSvyK6ThSCz5k+0oZQVlOUFpeyTvyNaXG6p4dutCPeJg46q66xtTuZliRc3AvC23d20luhjMmtct0PLQ+re023s4Bi7TQzbyJ+oUu0A6ntFl3lFHImcB2Bg+qEVJXh5PvQexMycxesEGGPV968s86oqs/uRoEEaCxgiV4fIDH6wD5buTJHLEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46534583be4f5071266559f4cff19142/e4706/homee_virtual_INSTAR_Camera_01.avif 230w", "/en/static/46534583be4f5071266559f4cff19142/d1af7/homee_virtual_INSTAR_Camera_01.avif 460w", "/en/static/46534583be4f5071266559f4cff19142/7f308/homee_virtual_INSTAR_Camera_01.avif 920w", "/en/static/46534583be4f5071266559f4cff19142/203bb/homee_virtual_INSTAR_Camera_01.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/46534583be4f5071266559f4cff19142/a0b58/homee_virtual_INSTAR_Camera_01.webp 230w", "/en/static/46534583be4f5071266559f4cff19142/bc10c/homee_virtual_INSTAR_Camera_01.webp 460w", "/en/static/46534583be4f5071266559f4cff19142/966d8/homee_virtual_INSTAR_Camera_01.webp 920w", "/en/static/46534583be4f5071266559f4cff19142/491b1/homee_virtual_INSTAR_Camera_01.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46534583be4f5071266559f4cff19142/81c8e/homee_virtual_INSTAR_Camera_01.png 230w", "/en/static/46534583be4f5071266559f4cff19142/08a84/homee_virtual_INSTAR_Camera_01.png 460w", "/en/static/46534583be4f5071266559f4cff19142/c0255/homee_virtual_INSTAR_Camera_01.png 920w", "/en/static/46534583be4f5071266559f4cff19142/c929c/homee_virtual_INSTAR_Camera_01.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/46534583be4f5071266559f4cff19142/c0255/homee_virtual_INSTAR_Camera_01.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Alarm_Areas.json"
        }}>{`Alarm Areas JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "alarm-area-sensitivity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-area-sensitivity",
        "aria-label": "alarm area sensitivity permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Area Sensitivity`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a53caef09192fa97fa179d880f125a3b/8affb/homee_virtual_INSTAR_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.608695652173912%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAwklEQVQI1wG3AEj/ANTE1NPC09vP3eHRpODMke3XwOzMrfLbw/f3+Pb3+PP19vb3+fXz4/Ty2+7t3trUjtrTgOPcv9PB2dHA0ADErsTDrMPOvc7YwoTWvG/oyaniuI3w0rTa4OPDztbK0tvV3ejj3qTc1X3i38bRynfNxmDd1LDJss/DrsEA283b2s3b4NXg5dew5dSl8NzJ7tO49OLP+Pr6/f38/////v7+/Pz4/v369PTr4t+s49+i5uPN0srZ287aC/aV4DyCNjAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a53caef09192fa97fa179d880f125a3b/e4706/homee_virtual_INSTAR_Camera_02.avif 230w", "/en/static/a53caef09192fa97fa179d880f125a3b/d1af7/homee_virtual_INSTAR_Camera_02.avif 460w", "/en/static/a53caef09192fa97fa179d880f125a3b/7f308/homee_virtual_INSTAR_Camera_02.avif 920w", "/en/static/a53caef09192fa97fa179d880f125a3b/86037/homee_virtual_INSTAR_Camera_02.avif 1254w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a53caef09192fa97fa179d880f125a3b/a0b58/homee_virtual_INSTAR_Camera_02.webp 230w", "/en/static/a53caef09192fa97fa179d880f125a3b/bc10c/homee_virtual_INSTAR_Camera_02.webp 460w", "/en/static/a53caef09192fa97fa179d880f125a3b/966d8/homee_virtual_INSTAR_Camera_02.webp 920w", "/en/static/a53caef09192fa97fa179d880f125a3b/a1214/homee_virtual_INSTAR_Camera_02.webp 1254w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a53caef09192fa97fa179d880f125a3b/81c8e/homee_virtual_INSTAR_Camera_02.png 230w", "/en/static/a53caef09192fa97fa179d880f125a3b/08a84/homee_virtual_INSTAR_Camera_02.png 460w", "/en/static/a53caef09192fa97fa179d880f125a3b/c0255/homee_virtual_INSTAR_Camera_02.png 920w", "/en/static/a53caef09192fa97fa179d880f125a3b/8affb/homee_virtual_INSTAR_Camera_02.png 1254w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a53caef09192fa97fa179d880f125a3b/c0255/homee_virtual_INSTAR_Camera_02.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Alarm_Areas_Sensitivity.json"
        }}>{`Alarm Areas Sensitivity JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pir-sensor",
        "aria-label": "pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PIR Sensor`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6ef623d82b97bbed845cabc537898ae0/6be49/homee_virtual_INSTAR_Camera_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "4.782608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/ANvN29/T393Q3eXc5unevOfZrfLj0/DYv/Pk1MvW3cDK1MTP2ufr8OvnwOHco+nmxNnR3tvO2t7S3uPY4xCQMtdkFc0nAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ef623d82b97bbed845cabc537898ae0/e4706/homee_virtual_INSTAR_Camera_03.avif 230w", "/en/static/6ef623d82b97bbed845cabc537898ae0/d1af7/homee_virtual_INSTAR_Camera_03.avif 460w", "/en/static/6ef623d82b97bbed845cabc537898ae0/7f308/homee_virtual_INSTAR_Camera_03.avif 920w", "/en/static/6ef623d82b97bbed845cabc537898ae0/e35d4/homee_virtual_INSTAR_Camera_03.avif 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6ef623d82b97bbed845cabc537898ae0/a0b58/homee_virtual_INSTAR_Camera_03.webp 230w", "/en/static/6ef623d82b97bbed845cabc537898ae0/bc10c/homee_virtual_INSTAR_Camera_03.webp 460w", "/en/static/6ef623d82b97bbed845cabc537898ae0/966d8/homee_virtual_INSTAR_Camera_03.webp 920w", "/en/static/6ef623d82b97bbed845cabc537898ae0/26598/homee_virtual_INSTAR_Camera_03.webp 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ef623d82b97bbed845cabc537898ae0/81c8e/homee_virtual_INSTAR_Camera_03.png 230w", "/en/static/6ef623d82b97bbed845cabc537898ae0/08a84/homee_virtual_INSTAR_Camera_03.png 460w", "/en/static/6ef623d82b97bbed845cabc537898ae0/c0255/homee_virtual_INSTAR_Camera_03.png 920w", "/en/static/6ef623d82b97bbed845cabc537898ae0/6be49/homee_virtual_INSTAR_Camera_03.png 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6ef623d82b97bbed845cabc537898ae0/c0255/homee_virtual_INSTAR_Camera_03.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_PIR_Sensor.json"
        }}>{`PIR Sensor JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "alarm-email",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-email",
        "aria-label": "alarm email permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Email`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4171118ebd706d0c4446587c3884e253/0d98f/homee_virtual_INSTAR_Camera_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.6521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AOLa4+HW4eHW4urk5evdt+vjw+jov+zeyOfEou3Wvtfg58rT287Y4+Xm1OrmvN/bpd7ZkuDZ4ODV4OTb5BeuMsb6l/nHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4171118ebd706d0c4446587c3884e253/e4706/homee_virtual_INSTAR_Camera_04.avif 230w", "/en/static/4171118ebd706d0c4446587c3884e253/d1af7/homee_virtual_INSTAR_Camera_04.avif 460w", "/en/static/4171118ebd706d0c4446587c3884e253/7f308/homee_virtual_INSTAR_Camera_04.avif 920w", "/en/static/4171118ebd706d0c4446587c3884e253/8faeb/homee_virtual_INSTAR_Camera_04.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4171118ebd706d0c4446587c3884e253/a0b58/homee_virtual_INSTAR_Camera_04.webp 230w", "/en/static/4171118ebd706d0c4446587c3884e253/bc10c/homee_virtual_INSTAR_Camera_04.webp 460w", "/en/static/4171118ebd706d0c4446587c3884e253/966d8/homee_virtual_INSTAR_Camera_04.webp 920w", "/en/static/4171118ebd706d0c4446587c3884e253/63399/homee_virtual_INSTAR_Camera_04.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4171118ebd706d0c4446587c3884e253/81c8e/homee_virtual_INSTAR_Camera_04.png 230w", "/en/static/4171118ebd706d0c4446587c3884e253/08a84/homee_virtual_INSTAR_Camera_04.png 460w", "/en/static/4171118ebd706d0c4446587c3884e253/c0255/homee_virtual_INSTAR_Camera_04.png 920w", "/en/static/4171118ebd706d0c4446587c3884e253/0d98f/homee_virtual_INSTAR_Camera_04.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4171118ebd706d0c4446587c3884e253/c0255/homee_virtual_INSTAR_Camera_04.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Alarm_Email.json"
        }}>{`Alarm Email JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "link-areas-with-pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#link-areas-with-pir-sensor",
        "aria-label": "link areas with pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Link Areas with PIR Sensor`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ad77373186587e00d82e3e6c9a9614f/844cc/homee_virtual_INSTAR_Camera_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.086956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AOTd5ebc5eXc5evl6u7ixO7ny+zrye7gy+3QtO3g0dXf5dLa4uLm5u/syujlxePenObj2eTc6Ofe5u3m7j3DNEmCcUcFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ad77373186587e00d82e3e6c9a9614f/e4706/homee_virtual_INSTAR_Camera_05.avif 230w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/d1af7/homee_virtual_INSTAR_Camera_05.avif 460w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/7f308/homee_virtual_INSTAR_Camera_05.avif 920w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/ff569/homee_virtual_INSTAR_Camera_05.avif 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ad77373186587e00d82e3e6c9a9614f/a0b58/homee_virtual_INSTAR_Camera_05.webp 230w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/bc10c/homee_virtual_INSTAR_Camera_05.webp 460w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/966d8/homee_virtual_INSTAR_Camera_05.webp 920w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/83135/homee_virtual_INSTAR_Camera_05.webp 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ad77373186587e00d82e3e6c9a9614f/81c8e/homee_virtual_INSTAR_Camera_05.png 230w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/08a84/homee_virtual_INSTAR_Camera_05.png 460w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/c0255/homee_virtual_INSTAR_Camera_05.png 920w", "/en/static/9ad77373186587e00d82e3e6c9a9614f/844cc/homee_virtual_INSTAR_Camera_05.png 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ad77373186587e00d82e3e6c9a9614f/c0255/homee_virtual_INSTAR_Camera_05.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Link_Areas.json"
        }}>{`Link Areas JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "push-notifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#push-notifications",
        "aria-label": "push notifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Push Notifications`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe098a31e10a6459da5fc1af53bc16bf/0d0e4/homee_virtual_INSTAR_Camera_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.521739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AOPc5OTa4+Ta5Ozm7Oziw+3kx+rpwezlzezQtu/WvN3i483W3tzi6+zqyuroyOLdo+bhs+Hb6OTa4uvj6zLXM9IdlCjhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe098a31e10a6459da5fc1af53bc16bf/e4706/homee_virtual_INSTAR_Camera_06.avif 230w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/d1af7/homee_virtual_INSTAR_Camera_06.avif 460w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/7f308/homee_virtual_INSTAR_Camera_06.avif 920w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/198fd/homee_virtual_INSTAR_Camera_06.avif 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe098a31e10a6459da5fc1af53bc16bf/a0b58/homee_virtual_INSTAR_Camera_06.webp 230w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/bc10c/homee_virtual_INSTAR_Camera_06.webp 460w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/966d8/homee_virtual_INSTAR_Camera_06.webp 920w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/fc6cc/homee_virtual_INSTAR_Camera_06.webp 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe098a31e10a6459da5fc1af53bc16bf/81c8e/homee_virtual_INSTAR_Camera_06.png 230w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/08a84/homee_virtual_INSTAR_Camera_06.png 460w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/c0255/homee_virtual_INSTAR_Camera_06.png 920w", "/en/static/fe098a31e10a6459da5fc1af53bc16bf/0d0e4/homee_virtual_INSTAR_Camera_06.png 1230w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe098a31e10a6459da5fc1af53bc16bf/c0255/homee_virtual_INSTAR_Camera_06.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Push.json"
        }}>{`Push Notifications JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarmserver",
        "aria-label": "alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a17b5c5b4140c254ce273425847c02c4/b1584/homee_virtual_INSTAR_Camera_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.086956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AOTd5eXb5eTb5Ori6+7kyu3lx+zqxe3hzO7Rte3f0NXe5NDZ4Nzi5+3ry+nmxuXfoOXh0+Pb5+fe5u7o7jpJNC481HwGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a17b5c5b4140c254ce273425847c02c4/e4706/homee_virtual_INSTAR_Camera_07.avif 230w", "/en/static/a17b5c5b4140c254ce273425847c02c4/d1af7/homee_virtual_INSTAR_Camera_07.avif 460w", "/en/static/a17b5c5b4140c254ce273425847c02c4/7f308/homee_virtual_INSTAR_Camera_07.avif 920w", "/en/static/a17b5c5b4140c254ce273425847c02c4/caefc/homee_virtual_INSTAR_Camera_07.avif 1342w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a17b5c5b4140c254ce273425847c02c4/a0b58/homee_virtual_INSTAR_Camera_07.webp 230w", "/en/static/a17b5c5b4140c254ce273425847c02c4/bc10c/homee_virtual_INSTAR_Camera_07.webp 460w", "/en/static/a17b5c5b4140c254ce273425847c02c4/966d8/homee_virtual_INSTAR_Camera_07.webp 920w", "/en/static/a17b5c5b4140c254ce273425847c02c4/ec5ae/homee_virtual_INSTAR_Camera_07.webp 1342w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a17b5c5b4140c254ce273425847c02c4/81c8e/homee_virtual_INSTAR_Camera_07.png 230w", "/en/static/a17b5c5b4140c254ce273425847c02c4/08a84/homee_virtual_INSTAR_Camera_07.png 460w", "/en/static/a17b5c5b4140c254ce273425847c02c4/c0255/homee_virtual_INSTAR_Camera_07.png 920w", "/en/static/a17b5c5b4140c254ce273425847c02c4/b1584/homee_virtual_INSTAR_Camera_07.png 1342w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a17b5c5b4140c254ce273425847c02c4/c0255/homee_virtual_INSTAR_Camera_07.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Alarmserver.json"
        }}>{`Alarmserver JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "alarm-trigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-trigger",
        "aria-label": "alarm trigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Use switches that automatically switch back to '0'. A button (without a second state) would be better here.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "873px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ccab1919b18f674347dcabaef83d647/35751/homee_virtual_INSTAR_Camera_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.956521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AO7u8eLf6uTh7+zo6u7ZxOnTvfPeydzg4b3J08HL1MHL1MnT3evr6Ojks+vor+jk39rP3dzQ293Q3eri6izgM29yO9Y3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ccab1919b18f674347dcabaef83d647/e4706/homee_virtual_INSTAR_Camera_08.avif 230w", "/en/static/9ccab1919b18f674347dcabaef83d647/d1af7/homee_virtual_INSTAR_Camera_08.avif 460w", "/en/static/9ccab1919b18f674347dcabaef83d647/7bc21/homee_virtual_INSTAR_Camera_08.avif 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ccab1919b18f674347dcabaef83d647/a0b58/homee_virtual_INSTAR_Camera_08.webp 230w", "/en/static/9ccab1919b18f674347dcabaef83d647/bc10c/homee_virtual_INSTAR_Camera_08.webp 460w", "/en/static/9ccab1919b18f674347dcabaef83d647/d21af/homee_virtual_INSTAR_Camera_08.webp 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ccab1919b18f674347dcabaef83d647/81c8e/homee_virtual_INSTAR_Camera_08.png 230w", "/en/static/9ccab1919b18f674347dcabaef83d647/08a84/homee_virtual_INSTAR_Camera_08.png 460w", "/en/static/9ccab1919b18f674347dcabaef83d647/35751/homee_virtual_INSTAR_Camera_08.png 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ccab1919b18f674347dcabaef83d647/35751/homee_virtual_INSTAR_Camera_08.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Alarm_Trigger.json"
        }}>{`Alarm Trigger JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "camera-reboot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-reboot",
        "aria-label": "camera reboot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Reboot`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Use switches that automatically switch back to '0'. A button (without a second state) would be better here.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "852px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f5b959811257051db7a4e929eed3cc6/47ff6/homee_virtual_INSTAR_Camera_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.826086956521738%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAgUlEQVQI1wXBUQ6CIAAAUO9/htY5css+rLY02TQQGsORoiDhQGS2NvvovUiKMGm38NyieCwPH5o43SEmkwweb4/4WgHMQ1jW9TuIU893o9i/FW1h/8xJpAZn9GRbNLG7YYXnQMs2rVCalQWkoG4w67z32/abLXHmvNiLn1VNmhfmf5K3Z6h81L7UAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f5b959811257051db7a4e929eed3cc6/e4706/homee_virtual_INSTAR_Camera_09.avif 230w", "/en/static/3f5b959811257051db7a4e929eed3cc6/d1af7/homee_virtual_INSTAR_Camera_09.avif 460w", "/en/static/3f5b959811257051db7a4e929eed3cc6/8954b/homee_virtual_INSTAR_Camera_09.avif 852w"],
              "sizes": "(max-width: 852px) 100vw, 852px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f5b959811257051db7a4e929eed3cc6/a0b58/homee_virtual_INSTAR_Camera_09.webp 230w", "/en/static/3f5b959811257051db7a4e929eed3cc6/bc10c/homee_virtual_INSTAR_Camera_09.webp 460w", "/en/static/3f5b959811257051db7a4e929eed3cc6/39392/homee_virtual_INSTAR_Camera_09.webp 852w"],
              "sizes": "(max-width: 852px) 100vw, 852px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f5b959811257051db7a4e929eed3cc6/81c8e/homee_virtual_INSTAR_Camera_09.png 230w", "/en/static/3f5b959811257051db7a4e929eed3cc6/08a84/homee_virtual_INSTAR_Camera_09.png 460w", "/en/static/3f5b959811257051db7a4e929eed3cc6/47ff6/homee_virtual_INSTAR_Camera_09.png 852w"],
              "sizes": "(max-width: 852px) 100vw, 852px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f5b959811257051db7a4e929eed3cc6/47ff6/homee_virtual_INSTAR_Camera_09.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Reboot.json"
        }}>{`Alarm Trigger JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "nightvision-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nightvision-mode",
        "aria-label": "nightvision mode permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nightvision Mode`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Would need a switching element with 3 states. Or 3 normal buttons. This element does not work at the moment.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd942027a551c87f53f5fd264b6b7efd/aa878/homee_virtual_INSTAR_Camera_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "8.260869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAe0lEQVQI1x3LSwrCMBQF0C7embtw4ho6UBFEUYQi1CLa0E8+zcu7viZxA6Jnfgrxs0zvxPkjXea74Aw6otnxZS1d1XtRgzOUclTst3ooVbMk94CdYVGw4TAGoZjRxqkCHcjtud7waYX2+jRBjb+c/tn05ateGH3zGrD8BTSHaP9NOsLoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd942027a551c87f53f5fd264b6b7efd/e4706/homee_virtual_INSTAR_Camera_10.avif 230w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/d1af7/homee_virtual_INSTAR_Camera_10.avif 460w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/7f308/homee_virtual_INSTAR_Camera_10.avif 920w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/e1c99/homee_virtual_INSTAR_Camera_10.avif 1380w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/758e9/homee_virtual_INSTAR_Camera_10.avif 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd942027a551c87f53f5fd264b6b7efd/a0b58/homee_virtual_INSTAR_Camera_10.webp 230w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/bc10c/homee_virtual_INSTAR_Camera_10.webp 460w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/966d8/homee_virtual_INSTAR_Camera_10.webp 920w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/445df/homee_virtual_INSTAR_Camera_10.webp 1380w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/47c81/homee_virtual_INSTAR_Camera_10.webp 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd942027a551c87f53f5fd264b6b7efd/81c8e/homee_virtual_INSTAR_Camera_10.png 230w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/08a84/homee_virtual_INSTAR_Camera_10.png 460w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/c0255/homee_virtual_INSTAR_Camera_10.png 920w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/b1001/homee_virtual_INSTAR_Camera_10.png 1380w", "/en/static/cd942027a551c87f53f5fd264b6b7efd/aa878/homee_virtual_INSTAR_Camera_10.png 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd942027a551c87f53f5fd264b6b7efd/c0255/homee_virtual_INSTAR_Camera_10.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Nightvision.json"
        }}>{`Nightvision JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "nightvision-ir-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nightvision-ir-leds",
        "aria-label": "nightvision ir leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nightvision IR LEDs`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f7f1a44b765390115d5bc19155827747/98432/homee_virtual_INSTAR_Camera_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.086956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASElEQVQI1wE9AML/AOPa5OTa5OPa4+ff6e3lz+3iwuvpxOvexuvJqenZx9Lc5NDY4e/x7u3rx+Xhud3XjeHc2OLY5OPZ4uzl7C0YM6hciI4eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7f1a44b765390115d5bc19155827747/e4706/homee_virtual_INSTAR_Camera_11.avif 230w", "/en/static/f7f1a44b765390115d5bc19155827747/d1af7/homee_virtual_INSTAR_Camera_11.avif 460w", "/en/static/f7f1a44b765390115d5bc19155827747/7f308/homee_virtual_INSTAR_Camera_11.avif 920w", "/en/static/f7f1a44b765390115d5bc19155827747/e1c99/homee_virtual_INSTAR_Camera_11.avif 1380w", "/en/static/f7f1a44b765390115d5bc19155827747/94304/homee_virtual_INSTAR_Camera_11.avif 1383w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f7f1a44b765390115d5bc19155827747/a0b58/homee_virtual_INSTAR_Camera_11.webp 230w", "/en/static/f7f1a44b765390115d5bc19155827747/bc10c/homee_virtual_INSTAR_Camera_11.webp 460w", "/en/static/f7f1a44b765390115d5bc19155827747/966d8/homee_virtual_INSTAR_Camera_11.webp 920w", "/en/static/f7f1a44b765390115d5bc19155827747/445df/homee_virtual_INSTAR_Camera_11.webp 1380w", "/en/static/f7f1a44b765390115d5bc19155827747/3f581/homee_virtual_INSTAR_Camera_11.webp 1383w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7f1a44b765390115d5bc19155827747/81c8e/homee_virtual_INSTAR_Camera_11.png 230w", "/en/static/f7f1a44b765390115d5bc19155827747/08a84/homee_virtual_INSTAR_Camera_11.png 460w", "/en/static/f7f1a44b765390115d5bc19155827747/c0255/homee_virtual_INSTAR_Camera_11.png 920w", "/en/static/f7f1a44b765390115d5bc19155827747/b1001/homee_virtual_INSTAR_Camera_11.png 1380w", "/en/static/f7f1a44b765390115d5bc19155827747/98432/homee_virtual_INSTAR_Camera_11.png 1383w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f7f1a44b765390115d5bc19155827747/c0255/homee_virtual_INSTAR_Camera_11.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_IR.json"
        }}>{`IR LEDs JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "preset-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preset-positions",
        "aria-label": "preset positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preset Positions`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Use switches that automatically switch back to '0'. A button (without a second state) would be better here.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "904px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02fc700f372ae6c4e61658a983152300/d9217/homee_virtual_INSTAR_Camera_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.826086956521738%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAgklEQVQI1wXB2w6CIAAAUP//V9paW+vFHmraLIZrpbBFYIAGcTHRqNZj5yRWTWH4fgyJTRFo8WthHByRFtQUVPRQMe3Gd3SPbtXx+Z3Pni730gvEBRaJaIxWvb+dLNroejted7JlKTiu8zKDKCsxE+o1WaNSJZe6XYR+fyEcQczO7A/PDGbW7PE0FAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02fc700f372ae6c4e61658a983152300/e4706/homee_virtual_INSTAR_Camera_12.avif 230w", "/en/static/02fc700f372ae6c4e61658a983152300/d1af7/homee_virtual_INSTAR_Camera_12.avif 460w", "/en/static/02fc700f372ae6c4e61658a983152300/c9047/homee_virtual_INSTAR_Camera_12.avif 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02fc700f372ae6c4e61658a983152300/a0b58/homee_virtual_INSTAR_Camera_12.webp 230w", "/en/static/02fc700f372ae6c4e61658a983152300/bc10c/homee_virtual_INSTAR_Camera_12.webp 460w", "/en/static/02fc700f372ae6c4e61658a983152300/82aba/homee_virtual_INSTAR_Camera_12.webp 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02fc700f372ae6c4e61658a983152300/81c8e/homee_virtual_INSTAR_Camera_12.png 230w", "/en/static/02fc700f372ae6c4e61658a983152300/08a84/homee_virtual_INSTAR_Camera_12.png 460w", "/en/static/02fc700f372ae6c4e61658a983152300/d9217/homee_virtual_INSTAR_Camera_12.png 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02fc700f372ae6c4e61658a983152300/d9217/homee_virtual_INSTAR_Camera_12.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Preset.json"
        }}>{`Preset JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "manual-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-recording",
        "aria-label": "manual recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Recording`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5789da89373103b11fa46b9fbc8a82c4/0f67e/homee_virtual_INSTAR_Camera_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.739130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAgUlEQVQI1wXBwQ6CIBgAYN//LTr1Bq118ZJrJREcaJgUDswUrVVk/AJrfV8SY5wg+CkG7yPYH1j/tVpl8jxXiuItPyJeElagvK+vHoIbHYzgPg4cJK/nW0tTXYZOa8PSBi/uYodYuVrTNGfLDeHy9uhJo2aDwSdaFXsuiBAH0cr2D9BuaeZltZvcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5789da89373103b11fa46b9fbc8a82c4/e4706/homee_virtual_INSTAR_Camera_13.avif 230w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/d1af7/homee_virtual_INSTAR_Camera_13.avif 460w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/7f308/homee_virtual_INSTAR_Camera_13.avif 920w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/10701/homee_virtual_INSTAR_Camera_13.avif 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5789da89373103b11fa46b9fbc8a82c4/a0b58/homee_virtual_INSTAR_Camera_13.webp 230w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/bc10c/homee_virtual_INSTAR_Camera_13.webp 460w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/966d8/homee_virtual_INSTAR_Camera_13.webp 920w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/cd6b8/homee_virtual_INSTAR_Camera_13.webp 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5789da89373103b11fa46b9fbc8a82c4/81c8e/homee_virtual_INSTAR_Camera_13.png 230w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/08a84/homee_virtual_INSTAR_Camera_13.png 460w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/c0255/homee_virtual_INSTAR_Camera_13.png 920w", "/en/static/5789da89373103b11fa46b9fbc8a82c4/0f67e/homee_virtual_INSTAR_Camera_13.png 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5789da89373103b11fa46b9fbc8a82c4/c0255/homee_virtual_INSTAR_Camera_13.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_Recording.json"
        }}>{`Recording JSON Export`}</a></li>
    </ul>
    <h2 {...{
      "id": "pan--tilt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pan--tilt",
        "aria-label": "pan  tilt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pan & Tilt`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Use switches that automatically switch back to '0'. A button (without a second state) would be better here.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c628631601794170f922a33e34959727/ae953/homee_virtual_INSTAR_Camera_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.434782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAzElEQVQI1z3Py26CQBgFYB7bN+jOtU/QumxcdWPipmlirDGhxqCVokAAmXB1mGHmvzRg0293cjbnODiiBx4QkdJ93cp7Z7Ru63LTVJfg9PzjTaW8G22UVEoq3fWOHQFSW2Q3d1nsl0UWbg7f79u9e4pF7gfHpyh4875mh92kqsqrH5/dc+iFiZ84dd1E1zxNGxH56ceLWM+LJDDWMhMzIQKiBQBx+xTZypgeCJFxGMjkAKDprQVuRZxvX8vdoiszJIYB/hsP8V8AHDrAX3A73uXcJw4UAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c628631601794170f922a33e34959727/e4706/homee_virtual_INSTAR_Camera_14.avif 230w", "/en/static/c628631601794170f922a33e34959727/d1af7/homee_virtual_INSTAR_Camera_14.avif 460w", "/en/static/c628631601794170f922a33e34959727/7f308/homee_virtual_INSTAR_Camera_14.avif 920w", "/en/static/c628631601794170f922a33e34959727/2c8e5/homee_virtual_INSTAR_Camera_14.avif 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c628631601794170f922a33e34959727/a0b58/homee_virtual_INSTAR_Camera_14.webp 230w", "/en/static/c628631601794170f922a33e34959727/bc10c/homee_virtual_INSTAR_Camera_14.webp 460w", "/en/static/c628631601794170f922a33e34959727/966d8/homee_virtual_INSTAR_Camera_14.webp 920w", "/en/static/c628631601794170f922a33e34959727/cc785/homee_virtual_INSTAR_Camera_14.webp 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c628631601794170f922a33e34959727/81c8e/homee_virtual_INSTAR_Camera_14.png 230w", "/en/static/c628631601794170f922a33e34959727/08a84/homee_virtual_INSTAR_Camera_14.png 460w", "/en/static/c628631601794170f922a33e34959727/c0255/homee_virtual_INSTAR_Camera_14.png 920w", "/en/static/c628631601794170f922a33e34959727/ae953/homee_virtual_INSTAR_Camera_14.png 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c628631601794170f922a33e34959727/c0255/homee_virtual_INSTAR_Camera_14.png",
              "alt": "homee virtuelle INSTAR MQTT Kamera",
              "title": "homee virtuelle INSTAR MQTT Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/images/Software/Other_Platforms/Homee_and_Node-RED/INSTAR_virtual_homee_Camera/Node-RED_PT.json"
        }}>{`Pan & Tilt JSON Export`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      